<script setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  Bars3Icon,
  XMarkIcon,
  BuildingStorefrontIcon,
  TicketIcon,
  HomeIcon,
} from "@heroicons/vue/24/outline";
import {
  QrCodeIcon,
  UserCircleIcon,
  XCircleIcon,
} from "@heroicons/vue/24/solid";
import useCurrentBusinessIdStore from "@/stores/currentBusinessIdStore";

const supabase = useSupabaseClient();
const user = useSupabaseUser();
const businessIdStore = useCurrentBusinessIdStore();
const { selectBusinessByUserId } = useBusinessManager();

const businessState = ref(null);

const fetchBusinessId = async () => {
  const business = await selectBusinessByUserId(user.value.id);
  businessIdStore.setBusinessId(business[0]?.business_id);
  businessState.value = business[0]?.status_id;
};

fetchBusinessId();

// console.log(user.value);
const navigation = computed(() => {
  let currentNavigation = [];
  if (user.value && user.value.user_metadata.role === "admin") {
    currentNavigation = [
      {
        name: "Inicio",
        href: "/dashboard",
        icon: HomeIcon,
        type: "admin",
        current: true,
      },
      {
        name: "Admisiones",
        href: "/dashboard/admin/business/new-admissions",
        icon: BuildingStorefrontIcon,
        type: "admin",
        current: true,
      },
      {
        name: "Comercios",
        href: "/dashboard/admin/business/published-list",
        icon: BuildingStorefrontIcon,
        type: "admin",
        current: true,
      },
      {
        name: "Cupones",
        href: "/dashboard/admin/coupon/list",
        icon: TicketIcon,
        type: "admin",
        current: true,
      },
    ];
  } else if (user.value && user.value.user_metadata.role === "business-admin") {
    currentNavigation = [
      {
        name: "Inicio",
        href: "/dashboard",
        icon: HomeIcon,
        type: "business",
        current: true,
      },
      {
        name: "Mi Comercio",
        href: "/dashboard/business-admin/business/edit/1",
        icon: BuildingStorefrontIcon,
        type: "business",
        current: false,
      },
    ];
  }
  if (
    user.value &&
    user.value.user_metadata.role === "business-admin" &&
    businessState.value >= 4
  ) {
    currentNavigation.push({
      name: "Mis Cupones",
      href: "/dashboard/business-admin/coupon/list",
      icon: TicketIcon,
      type: "business",
      current: false,
    });
    currentNavigation.push({
      name: "Aprobar un Canje",
      href: "/dashboard/business-admin/coupon/redeem",
      icon: QrCodeIcon,
      type: "business",
      current: false,
    });
  }
  return currentNavigation;
});
const teams = [];
const userNavigation = [
  { name: "Your profile", href: "#" },
  { name: "Sign out", href: "#" },
];

const sidebarOpen = ref(false);

const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    console.log(error);
  } else {
    return navigateTo("/auth/signin");
  }
};
</script>

<!--
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-white">
    <body class="h-full">
    ```
  -->
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-40 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute left-full top-0 flex w-16 justify-center pt-5"
                >
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div
                class="flex grow flex-col gap-y-5 overflow-y-auto bg-custom-fuchsia-07e px-6 pb-4"
              >
                <div class="flex h-16 shrink-0 items-center">
                  <a href="/dashboard">
                    <img class="h-12 w-auto" src="/logo.png" alt="Chicupón" />
                  </a>
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <a
                            :href="item.href"
                            :class="[
                              item.current
                                ? 'text-gray-50'
                                : 'text-gray-50 hover:text-gray-200',
                              'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                            ]"
                          >
                            <component
                              :is="item.icon"
                              :class="[
                                item.current
                                  ? 'text-gray-50'
                                  : 'text-gray-50 group-hover:text-gray-200',
                                'h-6 w-6 shrink-0',
                              ]"
                              aria-hidden="true"
                            />
                            {{ item.name }}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <!-- <div
                        class="text-xs font-semibold leading-6 text-gray-400"
                      >
                        Your teams
                      </div> -->
                      <ul role="list" class="-mx-2 mt-2 space-y-1">
                        <li v-for="team in teams" :key="team.name">
                          <a
                            :href="team.href"
                            :class="[
                              team.current
                                ? 'bg-gray-50 text-indigo-600'
                                : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                              'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                            ]"
                          >
                            <span
                              :class="[
                                team.current
                                  ? 'border-indigo-600 text-indigo-600'
                                  : 'border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600',
                                'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium',
                              ]"
                              >{{ team.initial }}</span
                            >
                            <span class="truncate">{{ team.name }}</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="mt-auto">
                      <p
                        class="group -mx-2 flex gap-x-3 rounded-md px-2 text-sm font-semibold capitalize text-gray-50"
                      >
                        <UserCircleIcon
                          class="h-6 w-6 shrink-0 text-gray-50"
                          aria-hidden="true"
                        />
                        {{ user?.user_metadata?.firstname + " " }}
                        {{ user?.user_metadata?.lastname }}
                      </p>
                      <button
                        class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-50 hover:text-gray-200"
                        @click="signOut"
                      >
                        <XCircleIcon
                          class="h-6 w-6 shrink-0 text-gray-50 group-hover:text-gray-200"
                          aria-hidden="true"
                        />
                        Cerrar sesión
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-white bg-custom-fuchsia-07e px-6 pb-4"
      >
        <div class="flex h-16 shrink-0 items-center">
          <a href="/dashboard">
            <img class="h-12 w-auto" src="/logo.png" alt="Chicupón" />
          </a>
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <a
                    :href="item.href"
                    :class="[
                      item.current
                        ? ' text-gray-50'
                        : 'text-gray-50  hover:text-gray-200',
                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                    ]"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        item.current
                          ? 'text-gray-50'
                          : 'text-gray-50 group-hover:text-gray-200',
                        'h-6 w-6 shrink-0',
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <!-- <div class="text-xs font-semibold leading-6 text-gray-400">
                Your teams
              </div> -->
              <ul role="list" class="-mx-2 mt-2 space-y-1">
                <li v-for="team in teams" :key="team.name">
                  <a
                    :href="team.href"
                    :class="[
                      team.current
                        ? 'bg-gray-50 text-indigo-600'
                        : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                    ]"
                  >
                    <span
                      :class="[
                        team.current
                          ? 'border-indigo-600 text-indigo-600'
                          : 'border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600',
                        'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium',
                      ]"
                      >{{ team.initial }}</span
                    >
                    <span class="truncate">{{ team.name }}</span>
                  </a>
                </li>
              </ul>
            </li>

            <li class="mt-auto">
              <a
                href="/dashboard/profile"
                class="group -mx-2 flex gap-x-3 rounded-md px-2 text-sm font-semibold capitalize text-gray-50 hover:text-gray-200"
              >
                <UserCircleIcon
                  class="h-6 w-6 shrink-0 text-gray-50 group-hover:text-gray-200"
                  aria-hidden="true"
                />
                {{ user?.user_metadata?.firstname + " " }}
                {{ user?.user_metadata?.lastname }}
              </a>
              <button
                class="group -mx-2 flex gap-x-3 rounded-md p-2 text-xs font-semibold leading-6 text-gray-50 hover:text-gray-200"
                @click="signOut"
              >
                <XCircleIcon
                  class="h-6 w-6 shrink-0 text-gray-50 group-hover:text-gray-200"
                  aria-hidden="true"
                />
                Cerrar sesión
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="lg:pl-72">
      <div
        class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-100 bg-white px-4 shadow-sm sm:h-8 sm:gap-x-6 sm:px-6 lg:px-8"
      >
        <button
          type="button"
          class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <!-- Separator -->
        <!-- <div class="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <form class="relative flex flex-1" action="#" method="GET">
            <label for="search-field" class="sr-only">Search</label>
            <MagnifyingGlassIcon
              class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
              aria-hidden="true"
            />
            <input
              id="search-field"
              class="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              placeholder="Search..."
              type="search"
              name="search"
            />
          </form>
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <button
              type="button"
              class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button> -->

        <!-- Separator -->
        <!-- <div
              class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
              aria-hidden="true"
            />

  
            <Menu as="div" class="relative">
              <MenuButton class="-m-1.5 flex items-center p-1.5">
                <span class="sr-only">Open user menu</span>
                <img
                  class="h-8 w-8 rounded-full bg-gray-50"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
                <span class="hidden lg:flex lg:items-center">
                  <span
                    class="ml-4 text-sm font-semibold leading-6 text-gray-900"
                    aria-hidden="true"
                    >Tom Cook</span
                  >
                  <ChevronDownIcon
                    class="ml-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                >
                  <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-slot="{ active }"
                  >
                    <a
                      :href="item.href"
                      :class="[
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900',
                      ]"
                      @click="signOut"
                      >{{ item.name }}</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>-->
        <!-- </div>
        </div>-->
      </div>
      <main class="pb-10">
        <div class="">
          <!--  -->
          <!-- Your content -->
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>
